<template>
  <div
    class="bg-divider text-xs text-headline flex items-center justify-center rounded-full font-semibold"
    :class="sizeClasses"
  >
    {{ value }}
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  value: {
    type: Number,
    required: true
  }
});

const sizeClasses = computed(() => {
  if (props.value < 100) {
    return "w-6 h-6";
  } else if (props.value < 1000) {
    return "w-7 h-7";
  } else if (props.value < 10000) {
    return "w-10 h-10";
  } else {
    return "w-12 h-12";
  }
});
</script>
